<template>
  <v-row>
    <v-dialog value="1" fullscreen eager transition="dialog-bottom-transition">
      <v-card class="card d-flex justify-center">
        <section class="text-center">
          <div class="d-flex justify-center mt-16 pt-16">
            <v-img max-width="395" class="card-logo" :src="logoURI" :lazy-src="logoURI" />
          </div>
          <PaymentTypeList v-if="!forValidation" :items="paymentTypes" @on-selected="onSelectedType" />          
          <section v-else>            
            <section v-if="!isDone">
              <h1 class="state-text mt-16 py-16">Please wait ...</h1>                        
              <img src="@/assets/img/menu/calculator.png" />
            </section>
            <section v-else>
              <PaymentConfirm v-if="paymentType.toLowerCase() === 'cash'"/>          
              <section v-else-if="paymentType.toLowerCase() === 'card'">
                <SuccessMessage v-if="payment_status" />
                <ErrorMessage v-else/>
              </section>   
            </section>    
          </section>
        </section>
      </v-card>
    </v-dialog>         
  </v-row>
</template> 
<script>
import { Order } from '@/services/SOLO'
import { mapGetters, mapMutations } from 'vuex';
import translationMixin from '@/mixins/translations'
import PaymentConfirm from '@/components/payment/PaymentConfirm.vue';
import SuccessMessage from '@/components/payment/SuccessMessage.vue';
import ErrorMessage from '@/components/payment/ErrorMessage.vue';
import PaymentTypeList from '@/components/payment/PaymentTypeList.vue';
export default {
  name: "PaymentPage",
  components:{
    PaymentTypeList,
    SuccessMessage,
    ErrorMessage,
    PaymentConfirm
  },
  mixins: [translationMixin],
  data:()=>({
    isDone: false,
    checkoutResponse: null,
    stateText: '',
    payment_status: null,
    forValidation: false,
    paymentType: ''
  }),
  computed:{   
    ...mapGetters({
      items: 'cart/items',
      getOrderType: 'app/getOrderType',
      checkoutAttempts: 'cart/checkoutAttempts',
      getConcept: 'concept/getConcept'
    }),    
    logoURI() {
      return this.getConcept['logo-uri']
    },
    paymentTypes(){
      return [
        {label: 'CASH', image: require('@/assets/img/dummy/payment/cash.png')},
        {label: 'CARD', image: require('@/assets/img/dummy/payment/card.png')},
      ]
    }
  },
  mounted() {
    this.stateText = this.translate('default_state','Please follow the payment instructions displayed on the pinpad below')
  },
  methods:{
    ...mapMutations({
      clear: 'cart/clear',
      icrementCheckoutAttempts: 'cart/icrementCheckoutAttempts'
    }),  
    onSelectedType(type){      
      this.paymentType = type.label      
      this.forValidation = true
      if(this.items.length) {
        this.validateOrder(this.checkoutPayload())
      } else {
        this.$router.push({ name: '/menu.index' });
      }
    },
    validateOrder(params) {			
			Order.validate(params).then(response => {        
        this.icrementCheckoutAttempts()
        this.checkoutOrder(params);					
      })
      .catch(err => {					
        console.error(err.response.data.error[0].detail)
      });
		},
		checkoutOrder(params) {
			Order.store(params).then(response => {									        
        this.checkoutResponse = response.data.data
        if(this.paymentType.toLowerCase() === 'card') {
          this.init()
        }        
      })
      .catch(err => {								          			
        console.error(err.response.data.error[0].detail)
      });
		},
    checkoutPayload() {
      return {				
				customer: null,
				source: "Kiosk",
				subtotal: this.getTotal(),				
				discount: 0,				
				device: null,
				type: this.getOrderType,
				"table-label": null,
				"vat-amount": 0,
				total: this.getTotal(),
				"coupon-code": '',
				items: this.getItems(),
				"delivery-charge": 0,
				address: null,
				location: 971,
				payment: {
					method: this.paymentType,
					...this.cashPresented(),
					amount: this.getTotal(),
				}
			};
    },
    getTotal() {
      return this.items.map(item => {
        return item.quantity * item.total
      }).reduce((a,b) => { return a + b }, 0)
    },
    getItems() {
      return this.items.map(item => {
        let comboItems = [];

        if (item.hasOwnProperty("combo-items") && item['combo-items'].length) {
          if (item["combo-items"].length) {
            item["combo-items"].map(combo => {
              comboItems.push({
                id: combo.id,
                price: combo.price,
                quantity: combo.quantity,
                ingredients: combo.ingredients,
                modifiers: combo.modifiers
                  .filter(option => {
                    return option.quantity;
                  })
                  .map(option => {
                    return {
                      id: option.id,
                      price: option.price,
                      quantity: option.quantity
                    };
                  })
              });
            });
          }
        }

        let comboItemObj = comboItems.length ? { "combo-items": comboItems } : {};
        return {
          id: item.id,
          price: item.price,
          quantity: item.quantity,
          notes: "notes",
          discount: item.discount,
          ingredients: item.hasOwnProperty("ingredients") ? item.ingredients.filter(ingredient => { return !ingredient.checked })
              .map(ingredient => {
                return {
                  id: ingredient.id,
                  quantity: ingredient.quantity
                };
              }) : [],
          modifiers: item.modifiers.map(modifier => {
            return {
              id: modifier.id,
              price: modifier.price,
              quantity: modifier.quantity
            };
          }),
          ...comboItemObj
        };
      })
    },
    cashPresented() {			
			let cashPresented = {
				"cash-presented": 0
			};

			return cashPresented;
		},
    printReciept() {
			let items = this.items.map(item => {
				return {
					name: {
						en: item.attributes.name[this.locales['en']],
						ar: item.attributes.name[this.locales['ar']]
					},
					price: item.price.toFixed(2),
					quantity: item.quantity,
					modifiers: item.modifiers.map(modifier => {
						return {
							name: {
								en: modifier.attributes.name[this.locales['en']],
								ar: modifier.attributes.name[this.locales['ar']]
							}
						}
					})
				}
			})

			let data = {
				branch: {
					en: 'TEST',
					ar: 'TEST'
				},
				order: {
					id: this.checkoutResponse.id,
					subtotal: (this.checkoutResponse.attributes.subtotal - this.checkoutResponse.attributes['vat-amount']).toFixed(2),
					vat: this.checkoutResponse.attributes['vat-amount'].toString(),
					total: Number(this.checkoutResponse.attributes.total).toFixed(2)
				},
				payment: {
					method: "CARD",
					balance: Number(this.checkoutResponse.attributes.total).toFixed(2),
					paid: Number(this.checkoutResponse.attributes.total).toFixed(2),
					remaining: "0.00"
				},
				items: items,
				discounts: []
			}	
			
			let paymentPayload = {
				method: 'card',
				amount: this.checkoutResponse.attributes.total,
				'cash-presented': 0,
				source: "offline"
			}
			
			Order.orderPayments(this.checkoutResponse.id, paymentPayload)
			Order.post(process.env.VUE_APP_PRINTER_URL, data)			
		},	
    paymentCancelled() {
      this.payment_status = true	
      this.stateText = this.translate('order_placed_message', 'Your Food order have been placed')            
      this.printReciept()            					
      setTimeout(() => {
        this.clear()
        this.$router.push({ name: 'home' });
      },15000)
    },
    paymentFailed(data) {
      this.isDone = true
      if(!data.p.payment.cancelled) {				
        this.paymentCancelled()
      } else if (this.checkoutAttempts < 2) {
        this.payment_status = false
        this.stateText = this.translate('payment_declined','Payment Declined!')            
        setTimeout(() => {						
          this.$router.push({ name:'menu.cart' });
        },15000)
      } else {
        this.payment_status = false
        this.stateText = this.translate('payment_declined','Payment Declined!')            
        setTimeout(() => {
          this.clear()
          this.$router.push({ name: 'home' });
        },5000)
      }		
    },  
    init() {
      
      let self = this      
      let socket = new WebSocket(process.env.VUE_APP_PAYMENT_ROOT)

      socket.onopen = () =>  {
        console.log("succesfully connected with web ecr server localhost server")
      }

      socket.onmessage = function (event) {
        console.log(`[message] Data received from server: ${event}`);
        
        const data = JSON.parse(event.data)

        if(data.m === 'ALERT' && data.p.status === 'AWAITING_REQUEST') {
          socket.send(
            JSON.stringify({
              m: "REQUEST",
              p: {
                req: "payment",
                body: {
                  price: this.getTotal().toString()
                }
              }
            })
          )          
        } else if(data.p.newState === 'INSERT_CARD') {
          self.stateText = self.translate('insert_card_state','Please insert your card')
        } else if(data.p.newState === 'ENTER_PIN') {
          self.stateText = self.translate('enter_pin_state','Please input your PIN')
        } else if(data.p.newState === 'PROCESSING') {
          self.stateText = self.translate('processing_state','Proccessing your payment, please wait...')
        } else if(data.p.newState === 'ENDED') {          
          self.paymentFailed(data)								
        } 
      }

      socket.onclose = function (event) {
        if (event.wasClean) {
        console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
        } else {          
        console.log("[close] Connection died");
        }
      }
  

      socket.onerror = function (error) {
        console.log(`[error] ${error.message}`);
      }
            
    }
  }
};
</script>
<style lang="scss" scoped>
.state-text {
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 128%;
  text-align: center;
  color: #1D1D1D;

}
</style>