<template>
  <section class="card px-15">
    <!-- <div class="card-content">
      <h1 class="card-title">Oh no, your payment failed</h1>
      <p class="card-description my-12">
        We were unable to process your last payment. This could have happend for
        a number of reasons.
      </p>
      <div class="card-details">
        Please check your details <a href="">here</a>.
      </div>
    </div> -->
    <img src="@/assets/img/menu/error.svg" />
  </section>
</template>
<script>
export default {
  name: "ErrorMessage",
};
</script>
<style lang="scss" scoped>
.card {
  &-content {
    margin: 150px 0;
  }
  &-title {
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    line-height: 128%;
    text-align: center;
    color: #1d1d1d;
  }
  &-description {
    font-style: normal;
    font-weight: 500;
    font-size: 56px;
    line-height: 100%;
    text-align: center;
  }
  &-details {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 100%;
    text-align: center;
    color: var(--v-primary-base);
  }
}
</style>