<template>
  <section class="d-flex justify-center card">
    <div>
      <h1 class="card-title mt-16 py-16">How would you like to pay?</h1>
      <PaymentTypeItem
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        v-on="$listeners"
      />
    </div>
  </section>
</template>
<script>
import PaymentTypeItem from "@/components/payment/PaymentTypeItem.vue";
export default {
  name: "PaymentTypeList",
  components: {
    PaymentTypeItem,
  },
  props: {
    items: {
      required: true,
      default: () => [],
    },
  },
};
</script>
<style scoped lang="scss">
.card {
  &-title {
    font-weight: bold;
    font-size: 56px;
    line-height: 100%;
    text-align: center;
    color: #1d1d1d;
  }
}
</style>