<template>
  <section class="card px-15">
    <!-- <h1 class="card-title">Please follow the payment instructions displayed on the pinpad below</h1> -->
    <img src="@/assets/img/menu/success.svg" />
  </section>
</template>
<script>
export default {
    name:'SuccessMessage'
}
</script>
<style lang="scss" scoped>
.card {
  &-title {
      margin: 150px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    line-height: 128%;
    text-align: center;
    color: #1d1d1d;
  }
}
</style>