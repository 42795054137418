<template>
  <v-card class="card shadow mb-10 d-flex align-center px-16" flat @click="$emit('on-selected', item)">
    <div class="d-flex align-center">
      <v-img :src="item.image"></v-img>
      <p class="mx-16 card-title">{{ item.label }}</p>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "PaymentTypeItem",
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  border-radius: 20px !important;
  background-color: #fff;
  width: 747px;
  min-height: 202px;
  &-title {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 100%;
    color: #1d1d1d;
  }
}
</style>