<template>
  <section class="card px-15">
    <h1 class="card-title">
      You can now proceed to the counter to pay for this
    </h1>
    <div class="d-flex justify-center card-image">
    <v-img :src="images[1].image" :max-width="images[1].width" />
    </div>
  </section>
</template>
<script>
export default {
  name: "PaymentConfirm",
  computed:{
      images(){                
          return [
              {width: '483', image: require('@/assets/img/menu/payment_1.png')},
              {width: '330', image: require('@/assets/img/menu/payment_2.png')},
          ]
      }
  }
};
</script>
<style lang="scss" scoped>
.card {
  &-title {
      margin: 150px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    line-height: 128%;
    text-align: center;
    color: #1d1d1d;
  }
}
</style>